<template>
  <div>
    <el-button type="primary" v-print="'#printInfo'" @click="recordPrint">打 印</el-button>
    <div id="printInfo">
      <div v-for="(showInfo, i) in showInfoList" :key="i" class="exhibit">
        <div class="emptyNode"></div>
        <div class="infoNode">
          <div class="flexNode">
            <div class="showInfoNode">
              <div class="flexNode">
                <div class="infodes">日期/DATE：</div>
                <span>{{ date[0] }}</span>
              </div>
              <div class="flexNode">
                <div class="infodes">场次/TIME：</div>
                <span>{{ date[1] }}</span>
              </div>
              <div class="flexNode">
                <div class="infodes">区域/AREA：</div>
                <span>{{ showInfo.区域名 }}区</span>
              </div>
              <div class="flexNode">
                <div class="infodes">座席/SEAT：</div>
                <span>{{ showInfo.名称 }}({{(showInfo.名称.match(/排(\w+)号/)[1])%2===0?'双号':'单号'}})</span>
              </div>
              <div class="flexNode">
                <div class="infodes">票种/TICKET：</div>
                <span>{{ ticketType }}</span>
              </div>
              <div class="flexNode">
                <div class="infodes">票价/PRICE：</div>
                <span>{{ showInfo.价格信息[0].原价 }}元</span>
              </div>
              <div>
                <div class="smallInfodes">（本场演出时长：{{ time }}分钟）</div>
              </div>
              <div>
                <div class="smallInfodes">订单号：{{ orderNum }}</div>
              </div>
            </div>
            <div class="showERCodeNode">
              <QRcode
                width="124"
                height="124"
                :content="showInfo.qrCode?showInfo.qrCode:orderNum"
                :load="true"
              ></QRcode>
            </div>
          </div>
          <div class="subInfoNode">
            <div>
              <span class="subInfo">{{ date[0] }}</span>
            </div>
            <div>
              <span class="subInfo">{{ date[1] }}</span>
            </div>
            <div>
              <span class="subInfo">{{ showInfo.区域名 }}区</span>
            </div>
            <div>
              <span class="subInfo">{{ ticketType }}</span>
            </div>
            <div>
              <span class="subInfo">{{ orderNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ticket",
  data() {
    return {
      showInfoList: this.$route.query.printInfo,
      date: this.$route.query.date,
      session: this.$route.query.session,
      orderNum: this.$route.query.orderNum,
      time: this.$route.query.time,
      ticketType: this.$route.query.ticketType,
      userId:this.$route.query.userId
    };
  },
  created() {
    console.log(this.date, this.showInfoList);
    for (var i = 0; i < this.showInfoList.length; i++) {
      let price = 178;
      if (this.showInfoList[i].区域名.startsWith("B")) price = 218;
      if (this.showInfoList[i].区域名.startsWith("A")) price = 258;
      if (this.showInfoList[i].区域名.startsWith("V")) price = 298;
      this.showInfoList[i].价格信息[0].原价 = price;
      if (this.showInfoList[i].ID){
        this.showInfoList[i].qrCode=this.uuidToBase64(this.showInfoList[i].ID)
      }
    }
  },
  methods: {
    uuidToBase64(uuid) {
      // 将UUID转换为Buffer
      const buffer = Buffer.from(uuid.replace(/-/g, ''), 'hex');
      // 将Buffer转换为Base64编码，并去掉填充符
      return buffer.toString('base64').replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_')
    },
    open(to, from, next) {
      next();
      //
      // this.$confirm("此操作将退出打印, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     console.log("确定");
      //   })
      //   .catch(() => {
      //     console.log("取消");
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },

    lockSeat() {},
    recordPrint(){
      this.$axios({
        method: "post",
        url: "/order/getTicket",
        data: {
          memberid: this.userId||window.sessionStorage.getItem("userId")||'0001',
          orderid: this.orderNum,
          auditID:window.sessionStorage.getItem("userId"),
          auditName:window.sessionStorage.getItem("userName")
        },
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('to, from, next: ', to, from, next);
    this.open(to, from, next);
  }
};
</script>

<style scoped>
.exhibit {
  /* width: 7.5cm; */
  /* height: 14.9cm; */
  width: 8cm;
  height: 16cm;
  margin-bottom: 3cm;
  page-break-after:always
  /* padding:0.1cm; */
  /* border: 1px solid red; */
}
.emptyNode {
  width: 100%;
  height: 12cm;
}
.infoNode {
  /* border: 1px solid red; */
  height: 7.5cm;
  width: 100%;
  padding: 0 0.1cm;
}
.showInfoNode {
  width: 65%;
  font-size: 0.38cm;
}
.showInfoNode .infodes {
  /* width: 2.2cm; */
}
.smallInfodes {
  font-size: 0.35cm;
  width: 9cm;
}
.subInfoNode {
  margin-top: 1.5cm;
  width: 100%;
  font-size: 0.4cm;
}
.showERCodeNode {
  width: 35%;
  margin-left: 0.3cm;
}
.flexNode {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

</style>
